import { scrollToTarget } from 'utils'

$(document).on('turbolinks:load', () => {
  let $btnDonate = $('.btn_donate')
  let $donateBlock = $('#donate-block')
  $btnDonate.addClass('buy_link')

  if ($donateBlock.length > 0) {
    $btnDonate.attr('href', 'javascript: void(0)')
  }

  if (window.location.hash === '#donate' && $donateBlock.length > 0) {
    scrollToTarget('#donate-block')
  }
})
