// This pack's primary purpose is to import application.scss.

// JS Rails routes
import * as Routes from 'routes.js.erb'
window.Routes = Routes

import 'site-main.scss'
import 'main/donate_block'
import 'main/volume_slider'
import 'bootstrap/js/src/modal'
import 'news/scroll_top_button'
import 'news/toggle_news_button'
import 'utils/banners'
