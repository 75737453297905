$(document).on('turbolinks:load', () => {
  const video = document.getElementById('hero_background_video')
  const volumeSlider = document.getElementById('volume-slider')
  const volumeToggle = document.getElementById('volume-toggle')
  let videoVolume = 0.3

  if (!video || !volumeSlider) return

  video.muted = true
  video.volume = videoVolume

  volumeToggle.addEventListener('click', function () {
    video.muted = !video.muted
    $('.volume-toggle').toggleClass('active')
    if (video.muted) {
      video.volume = 0
      volumeSlider.value = 0
    } else {
      video.volume = videoVolume
      volumeSlider.value = videoVolume
    }
  })

  volumeSlider.addEventListener('input', function () {
    videoVolume = volumeSlider.value
    video.volume = videoVolume
    if (video.volume === 0) {
      video.muted = true
      $('.volume-toggle').removeClass('active')
    } else {
      video.muted = false
      $('.volume-toggle').addClass('active')
    }
  })
})

$(document).on('turbolinks:before-cache', () => {
  const volumeSlider = document.getElementById('volume-slider')
  const $volumeToggle = $('.volume-toggle')

  if ($volumeToggle.length === 0) return

  $volumeToggle.removeClass('active')
  volumeSlider.value = 0
})
