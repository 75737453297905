$(document).on('turbolinks:load', () => {
  let $scrollUpButton = $('.scroll-top-button')

  $scrollUpButton.on('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  })

  $(document).on('scroll', () => {
    const scrollOffset = $(document).scrollTop()
    const $newsContentBlock = $('.news-content-block')
    if (!$newsContentBlock || !$newsContentBlock.offset()) return

    const newsOffset =
      $newsContentBlock.offset().top + $newsContentBlock.outerHeight(true)

    if (scrollOffset < newsOffset - window.innerHeight && scrollOffset > 300) {
      $scrollUpButton.parent().addClass('fixed')
    } else {
      $scrollUpButton.parent().removeClass('fixed')
    }
  })
})
